<template lang="pug">
  main
    HeaderWhite(ref="headerwhite")
    b-container#main-container
      b-modal(
        id="modalpdf" @hidden="clear_formpdf" hide-footer @close="clear_formpdf"
      )
        el-form(:rules="rules" ref="formpdf" :model="formpdf")
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          FormInput(
            label="Provincia" name="store_province"
            :model="formpdf.store_province" v-model="formpdf.store_province"
            :options="provinces" :multiselect="true"
          )
          FormInput(
            label="Tipologia di richiesta" name="type"
            :model="formpdf.type" v-model="formpdf.type"
            :options="requests_type" :multiselect="true"
          )
          FormInput(
            label="Stato di richiesta" name="status"
            :model="formpdf.status" v-model="formpdf.status"
            :options="requests_status" :multiselect="true"
          )
          b-button(type="button" variant="primary" @click="onSubmitPdf") Visualizza
      div
        AdminSidebar(nav="operations")
        aside._body
          b-nav#header
            .d-flex
              b-button(
                v-b-modal.modalform variant="primary" href="/operations/new"
              )
                | Nuova richiesta d'intervento
              b-button(
                v-b-modal.modalpdf
                style="margin: 0 10px"
              ) Genera PDF
              b-form-checkbox(v-model="archived" button button-variant="outline-secondary" @change="seeArchive")
                | Archivio
          el-form#filters
            h4 Filtra
            b-row
              b-col
                label ID
                el-input(
                  v-model="filters.id"
                  :clearable="true"
                )
              b-col
                label Tipo
                FormInput(
                  label="" name="type"
                  :model="filters.type" v-model="filters.type"
                  :options="[{value: 'FO', text: 'Forfait'}, {value: 'EX', text: 'Extraforfait'}]"
                  :clearable="true"
                )
              b-col
                label Stato
                FormInput(
                  label="" name="status"
                  :model="filters.status" v-model="filters.status"
                  :options="[{value: 'OP', text: 'Aperta'}, {value: 'WA', text: 'In attesa'}, {value: 'CL', text: 'Chiusa'}, {value: 'CA', text: 'Annullata'}]"
                  :clearable="true"
                )
              b-col
                label Punto vendita
                FormInput(
                  label="" name="pv"
                  :model="filters.pv" v-model="filters.pv"
                  :options="stores"
                  :clearable="true"
                )
              b-col
                label Provincia
                FormInput(
                  label="" name="province"
                  :model="filters.province" v-model="filters.province"
                  :options="provinces"
                  :clearable="true"
                )
              b-col
                label Data di creazione
                el-date-picker(
                  locale="it-IT" v-model="filters.created" format="dd/MM/yyyy"
                  :clearable="true"
                )
              b-col
                label Scadenza
                el-date-picker(
                  locale="it-IT" v-model="due_filter" format="dd/MM/yyyy"
                  :clearable="true"
                )
            b-row
              b-col
                label Note
                el-input(
                  v-model="filters.note"
                  :clearable="true"
                )
          b-table(
              id="my-table"
              responsive hover 
              :items="operations" 
              :fields="fields"
              sort-by="id"
              :sort-desc="true"
              :sort-compare="sortCompare"
              :per-page="perPage"
              :current-page="currentPage"
              small
          )
            template(#cell(id)="data")
              span {{ data.item.id }}
            template(#cell(created)="data")
              span {{ formate_date(data.item.created) }}
            template(#cell(due)="data")
              span(v-if="data.item.due") {{ formate_date(data.item.due) }}
            template(#cell(status)="data")
                el-select(
                  style="width:100px !important"
                  name="status"
                  v-model="data.item.status"
                  @change="editOp(data.item)"
                )
                  el-option(
                    v-for="item in requests_status"
                    :key="item.value" :label="item.text" :value="item.value"
                  )
            template(#cell(details)="row")
              b-button(
                style="float:left" :href="'/operations/'+row.item.id+'/edit'"
              ) Gestisci
              b-button(
                size="sm" variant="warning"
                @click="handleArchive(row.item.id, row.item.is_archived)"
              )
                b-icon-folder(v-if="!row.item.is_archived")
                b-icon-folder2-open(v-else)

            template(#row-details="extend")
              b-card
                p {{ extend.item.note }}
          #pagination
            b-pagination(
              v-model="currentPage" 
              :total-rows="count" 
              :per-page="perPage"
              aria-controls="my-table"
            )
</template>

<script>
import HeaderWhite from "@/components/header-white.vue";
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";

export default {
  name: "Operations",
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput
  },
  data() {
    return {
      operations: [],
      stores: [],
      formpdf: {
        store_province: ["CT", "ME", "EN", "SR", "RG", "CL"]
      },
      archived: false,
      fields: [
        { key: "show_details", label: "" },
        { key: "id", label: "ID", sortable: true },
        { key: "created", label: "Creato", sortable: true },
        { key: "type", label: "Tipo", sortable: true },
        { key: "due", label: "Scadenza", sortable: true },
        { key: "store", label: "PV", sortable: true },
        { key: "status", label: "Stato", sortable: true },
        { key: "details", label: "" }
      ],
      requests_type: [
        { value: "FO", text: "Forfait" },
        { value: "EX", text: "Extraforfait" }
      ],
      requests_status: [
        { value: "OP", text: "Aperta" },
        { value: "WA", text: "In attesa" },
        { value: "CL", text: "Chiusa" },
        { value: "CA", text: "Annullata" }
      ],
      filters: {},
      due_filter: null,
      provinces: [],
      rules: {
        store_province: [
          { required: true, message: "La provincia è obbligatorio" }
        ],
        status: [{ required: true, message: "Lo stato è obbligatorio" }]
      },
      currentPage: 1,
      pageNum: 1,
      perPage: 10,
      count: 0
    };
  },
  watch: {
    filters: {
      handler() {
        this.filterBy();
      },
      deep: true
    }
  },
  mounted() {
    this.archived = this.$route.query.archived !== undefined;
    this.access_token = this.$cookies.get("access_token");
    const query = this.$route.query;
    this.pageNum = parseInt(query["p"]) || 1;

    if (query["type"]) this.filters.type = query["type"];
    if (query["status"]) this.filters.status = query["status"];
    if (query["pv"]) this.filters.pv = query["pv"];
    if (query["province"]) this.filters.province = query["province"];
    if (query["created"]) this.filters.created = query["created"];
    if (query["due"]) this.filters.due = query["due"];
    if (query["note"]) this.filters.note = query["note"];

    this.getData(true);
    setTimeout(() => {
      this.$set(this.$data, "currentPage", this.pageNum);
    }, 1000);

    fetch("/provinces.json").then(async res => {
      for (const i of await res.json()) {
        this.provinces.push({ text: i.text, value: i.value });
      }
    });

    fetch(`${process.env.VUE_APP_BACKEND_URL}/stores/?limit=1000`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`
      }
    }).then(async response => {
      const stores = await response.json();
      for (const store of stores.results) {
        this.stores.push({
          text: `${store.pv} - ${store.customer.corporate_name} - ${store.street}, ${store.city}`,
          value: store.pv
        });
      }
    });
  },
  methods: {
    filterBy() {
      if (this.filters.created) {
        this.filters.created = this.$moment(this.filters.created).format(
          "YYYY-MM-DD"
        );
      } else {
        delete this.filters.created;
      }

      if (this.due_filter) {
        this.filters.due = this.$moment(this.due_filter)
          .add(-1, "days")
          .format("YYYY-MM-DD");
      } else {
        delete this.filters.due;
      }

      for (const field of Object.keys(this.filters)) {
        if (!this.filters[field].length) {
          delete this.filters[field];
        }
      }
      this.getData(true);
    },
    getData(have_filter) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/operation-requests/`;
      let body = null;
      if (have_filter) {
        route += "filter/";
        body = JSON.stringify(this.filters);
      }
      route += "?limit=100000";
      if (this.archived) {
        route += "&archived";
      }
      fetch(route, {
        method: have_filter ? "POST" : "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json"
        },
        body
      }).then(async response => {
        const data = await response.json();
        this.operations = data.results;
        this.count = data.count;
        for (let operation of this.operations) {
          if (operation.note != "") operation._showDetails = true;
        }
      });
    },
    changePag(num) {
      let url = this.$route.path + "?_=1";
      if (num > 1) url += "&p=" + num;

      for (const field of Object.keys(this.filters)) {
        if (this.filters[field] && this.filters[field].length) {
          url += "&" + field + "=" + this.filters[field];
        }
      }

      window.location.href = url;
    },
    seeArchive() {
      if (this.archived) {
        window.location.href = "/operations?archived";
      } else {
        window.location.href = "/operations";
      }
    },
    clear_formpdf() {
      this.formpdf = {
        store_province: ["CT", "ME", "EN", "SR", "RG", "CL"],
        type: "",
        status: ""
      };
    },
    formate_date(text) {
      const _date = this.$moment(text, "YYYY-MM-DD hh:mm:ss");
      return _date.format("DD/MM/YYYY HH:mm");
    },
    sortCompare(
      aRow,
      bRow,
      key,
      sortDesc,
      formatter,
      compareOptions,
      compareLocale
    ) {
      let a = aRow[key];
      let b = bRow[key];
      if (key == "due" || key == "created") {
        a = this.$moment(a, "YYYY-MM-DD hh:mm:ss").unix();
        b = this.$moment(b, "YYYY-MM-DD hh:mm:ss").unix();
      }

      if (typeof a === "number" && typeof b === "number") {
        return a < b ? -1 : a > b ? 1 : 0;
      } else {
        return this.toString(a).localeCompare(
          this.toString(b),
          compareLocale,
          compareOptions
        );
      }
    },
    toString(value) {
      if (value === null || typeof value === "undefined") {
        return "";
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => this.toString(value[key]))
          .join(" ");
      } else {
        return String(value);
      }
    },
    handleArchive(id, is_archived) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/operation-requests/${id}/`;

      fetch(route, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ is_archived: !is_archived })
      })
        .then(() => {
          this.$bvToast.toast("Richiesta d'intervento salvata con successo", {
            title: "Modifica effettuata",
            variant: "success",
            solid: true
          });
          for (const index in this.operations) {
            if (this.operations[index].id == id) {
              this.operations.splice(index, 1);
              break;
            }
          }
        })
        .catch(e => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true
          });
        });
    },
    async onSubmitPdf() {
      this.$refs["formpdf"].validate(async valid => {
        if (valid) {
          this.$refs.headerwhite.loading = true;
          let route = `${process.env.VUE_APP_BACKEND_URL}/operation-requests/pdf/`;
          this.formpdf = {
            store_province: this.formpdf.store_province.join(","),
            type: this.formpdf.type.join(","),
            status: this.formpdf.status.join(",")
          };

          if (!this.formpdf.type) {
            delete this.formpdf.type;
          }

          await fetch(route, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`
            },
            body: JSON.stringify(this.formpdf)
          })
            .then(async r => ({
              filename: "operations.pdf",
              blob: await r.blob(),
              headers: await r.headers
            }))
            .then(obj => {
              const newBlob = new Blob([obj.blob], { type: "application/pdf" });

              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
              } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);

                let link = document.createElement("a");
                link.href = objUrl;
                link.download = obj.filename;
                link.click();

                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                  window.URL.revokeObjectURL(objUrl);
                }, 250);
                this.$bvModal.hide("modalpdf");
              }
            })
            .catch(e => {
              this.$bvToast.toast(e.status, {
                title: "Errore nella generazione del PDF",
                variant: "danger",
                solid: true
              });
            })
            .finally(() => {
              this.$refs.headerwhite.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    editOp(item) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/operation-requests/${item.id}/`;
      fetch(route, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`
        },
        method: "PATCH",
        body: JSON.stringify(item)
      })
        .then(async r => {
          if (r.status == 200) {
            this.$bvToast.toast("Richiesta salvata con successo", {
              title: "Modifica effettuata",
              variant: "success",
              solid: true
            });
          } else {
            this.$bvToast.toast(JSON.stringify(await r.json()), {
              title: "Errore nel salvataggio",
              variant: "danger",
              solid: true
            });
          }
        })
        .catch(e => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>
