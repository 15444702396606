<template lang="pug">
  main
    HeaderWhite
    b-container#main-container
      b-modal(
        id="modalform" @hidden="form={}" hide-footer
        @close="handleClose" @hide="handleClose"
      )
        el-form(:rules="rules" label-width="90px" ref="form" :model="form")
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          FormInput(
            label="PV" :model="form.pv" v-model="form.pv" name="pv"
          )
          //el-form-item(label="PV" v-else)
            el-input(
              label="PV" :value="form.pv")*/
          FormInput(
            label="PBL" :model="form.pbl" v-model="form.pbl" name="pbl"
          )
          FormInput(
            label="Cliente" :options="customers"
            :model="form.customer" v-model="form.customer" name="customer"
          )
          FormInput(
            label="Bandiera" :model="form.flag" v-model="form.flag" name="flag"
          )
          FormInput(
            label="Via" :model="form.street"
            v-model="form.street" name="street"
          )
          FormInput(
            label="Città" :model="form.city" v-model="form.city" name="city"
          )
          FormInput(
            label="Provincia" :model="form.province" :options="provinces"
            v-model="form.province" name="province"
          )
          FormInput(
            label="Regione" :model="form.region" :options="regions"
            v-model="form.region" name="region"
          )
          FormInput(
            label="Gestore"
            :model="form.manager" v-model="form.manager" name="manager"
          )
          FormInput(
            label="Numeri di telefono" :text="true"
            :model="form.telephone_numbers"
            v-model="form.telephone_numbers" name="telephone_numbers"
          )
          FormInput(
            label="Giorni di chiusura" :text="true"
            :model="form.closing_days"
            v-model="form.closing_days" name="closing_days"
          )
          FormInput(
            label="Note" :text="true"
            :model="form.note" v-model="form.note" name="note"
          )
          b-button(type="button" variant="primary" @click="onSubmit") Salva
      div
        AdminSidebar(nav="stores")
        aside._body
          b-nav#header
            b-button(v-b-modal.modalform variant="primary") Nuovo punto vendita
          el-form#filters
            h4 Filtra
            b-row
              b-col
                label PV
                el-input(
                  v-model="filters.pv"
                  :clearable="true"
                )
              b-col
                label PBL
                el-input(
                  v-model="filters.pbl"
                  :clearable="true"
                )
              b-col
                label Cliente
                FormInput(
                  label="" name="customer"
                  :model="customerFilter" v-model="customerFilter"
                  :options="customers"
                  :clearable="true"
                )
              b-col
                label Indirizzo
                el-input(
                  v-model="filters.street"
                  :clearable="true"
                )
              b-col
                label Provincia
                el-input(
                  v-model="filters.province"
                  :clearable="true"
                )
              b-col
                label Regione
                el-input(
                  v-model="filters.region"
                  :clearable="true"
                )
              b-col
                label Numeri di telefono
                el-input(
                  v-model="filters.telephone_numbers"
                  :clearable="true"
                )
          b-table(
            responsive fixed hover :items="stores" :fields="fields"
          )
            template(#cell(details)="row")
              b-button(size="sm" @click="row.toggleDetails" class="mr-2")
                | {{ row.detailsShowing ? 'Nascondi' : 'Mostra'}} dettagli
              b-button(
                size="sm" class="mr-2" variant="light" v-b-modal.modalform
                @click="handleEdit(row.item, row.index)"
              )
                b-icon-pencil
              b-button(
                size="sm" variant="danger" @click="handleDelete(row.index)"
              )
                b-icon-trash
            template(#row-details="row")
              b-card#details
                b-row
                  b-col(cols="6" lg="4")
                    b PV: 
                    span {{ row.item.pv }}
                  b-col(cols="6" lg="4")
                    b PBL: 
                    span {{ row.item.pbl }}
                  b-col(cols="6" lg="4")
                    b Cliente: 
                    span {{ row.item.customer.corporate_name }}
                  b-col(cols="6" lg="4")
                    b Bandiera: 
                    span {{ row.item.flag }}
                  b-col(cols="6" lg="4")
                    b Via: 
                    span {{ row.item.street }}
                  b-col(cols="6" lg="4")
                    b Città: 
                    span {{ row.item.city }}
                  b-col(cols="6" lg="4")
                    b Provincia: 
                    span {{ row.item.province }}
                  b-col(cols="6" lg="4")
                    b Regione: 
                    span {{ row.item.region }}
                  b-col(cols="6" lg="4")
                    b Gestore: 
                    span {{ row.item.manager }}
                  b-col(cols="6" lg="4")
                    b Giorni di chiusura: 
                    span {{ row.item.closing_days }}
                  b-col(cols="6" lg="4")
                    b Numeri di telefono: 
                    span {{ row.item.telephone_numbers }}
                  b-col(cols="6" lg="4")
                    b Note: 
                    span {{ row.item.note }}
          #pagination
            b-pagination(
              v-model="currentPage" :total-rows="count" per-page="20"
              first-number last-number @change="changePag($event)"
            )
</template>

<script>
import HeaderWhite from "@/components/header-white.vue";
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";

export default {
  name: "Store",
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput
  },
  data() {
    var alphanumeric = (rule, value, callback) => {
      if (/[^a-zA-Z0-9]/.test(value)) {
        callback(new Error("Il testo non è alfanumerico"));
      } else {
        callback();
      }
    };
    return {
      pvVerify: "",
      stores: [],
      customers: [],
      form: {
        _e: false,
        telephone_numbers: "",
        closing_days: "",
        note: ""
      },
      temp_form: {
        index: null,
        data: {
          _e: false,
          telephone_numbers: "",
          closing_days: "",
          note: ""
        }
      },
      fields: [
        { key: "pv", label: "PV" },
        { key: "pbl", label: "PBL" },
        { key: "customer.corporate_name", label: "Cliente" },
        { key: "street", label: "Indirizzo" },
        { key: "telephone_numbers", label: "Telefono" },
        { key: "details", label: "" }
      ],
      regions: [],
      provinces: [],
      rules: {
        pv: [
          { required: true, message: "Il PV è obbligatorio" },
          { max: 8, message: "Il PV può essere lungo fino a 8 caratteri" },
          { validator: alphanumeric }
        ],
        pbl: [
          { required: true, message: "Il PBL è obbligatorio" },
          { max: 8, message: "Il PBL può essere lungo fino a 8 caratteri" }
        ],
        customer: [{ required: true, message: "Il cliente è obbligatorio" }],
        flag: [
          { required: true, message: "La bandieta è obbligatoria" },
          { max: 10, message: "La città può essere lunga fino a 10 caratteri" }
        ],
        street: [
          { required: true, message: "L'indirizzo è obbligatorio" },
          {
            max: 30,
            message: "L'indirizzo può essere lungo fino a 30 caratteri"
          }
        ],
        city: [
          { required: true, message: "La città è obbligatoria" },
          { max: 30, message: "La città può essere lunga fino a 30 caratteri" }
        ],
        province: [
          { required: true, message: "La provincia è obbligatoria" },
          {
            max: 2,
            message: "La provincia può essere lunga fino a 2 caratteri"
          }
        ],
        region: [
          { required: true, message: "La regione è obbligatoria" },
          {
            max: 15,
            message: "La regione può essere lunga fino a 15 caratteri"
          }
        ],
        manager: [
          { required: true, message: "Il gestore è obbligatorio" },
          {
            max: 30,
            message: "Il gestore può essere lungo fino a 30 caratteri"
          }
        ]
      },
      filters: {},
      customerFilter: null,
      count: 0,
      currentPage: 1,
      pageNum: 1
    };
  },
  watch: {
    filters: {
      handler() {
        this.filterBy();
      },
      deep: true
    },
    customerFilter: function() {
      this.filterBy();
    }
  },
  mounted() {
    this.access_token = this.$cookies.get("access_token");
    this.pageNum = parseInt(this.$route.query["p"]) || 1;

    const query = this.$route.query;

    if (query["pv"]) this.filters.pv = query["pv"];
    if (query["pbl"]) this.filters.pbl = query["pbl"];
    if (query["customer"]) {
      this.customerFilter = query["customer"];
      this.filters.customer = query["customer"];
    }
    if (query["street"]) this.filters.street = query["street"];
    if (query["province"]) this.filters.province = query["province"];
    if (query["region"]) this.filters.region = query["region"];
    if (query["telephone_numbers"])
      this.filters.telephone_numbers = query["telephone_numbers"];

    this.getData();
    setTimeout(() => {
      this.$set(this.$data, "currentPage", this.pageNum);
    }, 800);

    fetch(`${process.env.VUE_APP_BACKEND_URL}/customers/?limit=1000`, {
      headers: {
        Authorization: `Bearer ${this.access_token}`
      }
    }).then(async response => {
      const data = await response.json();
      for (const customer of data.results) {
        this.customers.push({
          text: customer.corporate_name,
          value: customer.id
        });
      }
    });

    fetch("/regions.json").then(async res => {
      for (const i of await res.json()) {
        this.regions.push({ text: i, value: i });
      }
    });

    fetch("/provinces.json").then(async res => {
      for (const i of await res.json()) {
        this.provinces.push({ text: i.text, value: i.value });
      }
    });
  },
  methods: {
    filterBy() {
      for (const field of Object.keys(this.filters)) {
        if (!this.filters[field].length) {
          delete this.filters[field];
        }
      }

      if (this.customerFilter) {
        this.filters.customer = this.customerFilter;
      }
      this.getData(true);
    },
    getData(have_filter) {
      let route = `${process.env.VUE_APP_BACKEND_URL}/stores/`;
      let body = null;
      if (have_filter) {
        route += "filter/";
        body = JSON.stringify(this.filters);
      }
      route += "?limit=20&offset=" + (this.pageNum - 1) * 20;
      fetch(route, {
        method: have_filter ? "POST" : "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json"
        },
        body
      }).then(async response => {
        const data = await response.json();
        this.stores = data.results;
        this.count = data.count;
      });
    },
    changePag(num) {
      let url = this.$route.path + "?_=1";
      if (num > 1) url += "&p=" + num;

      for (const field of Object.keys(this.filters)) {
        if (this.filters[field] && this.filters[field].length) {
          url += "&" + field + "=" + this.filters[field];
        }
      }

      window.location.href = url;
    },
    formate_date(text) {
      const _date = this.$moment(text, "YYYY-MM-DD hh:mm:ss");
      return _date.format("DD/MM/YYYY HH:mm");
    },
    handleEdit(data, index) {
      this.id = data.id;
      this.pvVerify = data.pv;
      this.temp_form.index = index;
      this.temp_form.data = JSON.parse(JSON.stringify(data));
      this.temp_form.data._e = true;
      this.form = data;
      this.form._e = true;
      this.form.customer = data.customer.id;
    },
    handleClose() {
      if (this.temp_form.index != null) {
        this.$set(this.stores, this.temp_form.index, this.temp_form.data);
        this.temp_form = {
          index: null,
          data: {
            _e: false,
            telephone_numbers: "",
            closing_days: "",
            note: ""
          }
        };
      }
    },
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let path = "";
          if (this.form._e) {
            path = `${process.env.VUE_APP_BACKEND_URL}/stores/${this.id}/`;
          } else {
            path = `${process.env.VUE_APP_BACKEND_URL}/stores/`;
          }
          const action = {
            method: this.form._e ? "PUT" : "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`
            }
          };

          this.loading = true;
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(this.form)
          })
            .then(async r => {
              const st = await r.status;
              if (st == 200 || st == 201) {
                this.$bvToast.toast("Punto vendita salvato con successo", {
                  title: "Modifica effettuata",
                  variant: "success",
                  solid: true
                });
                const data = await r.json();
                // get data about `customer_id`
                await fetch(
                  `${process.env.VUE_APP_BACKEND_URL}/customers/${this.form.customer}/`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.access_token}`
                    }
                  }
                ).then(async res => {
                  data.customer = await res.json();
                });

                if (this.form._e) {
                  // copy `pv` because response hasn't `pv` field
                  data.pv = this.form.pv;

                  this.$set(this.stores, this.temp_form.index, data);
                  this.temp_form = {
                    index: null,
                    data: {
                      _e: false,
                      telephone_numbers: "",
                      closing_days: "",
                      note: ""
                    }
                  };
                } else {
                  this.stores.push(data);
                }
                this.$bvModal.hide("modalform");
                this.form = {};
              } else {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Errore nel salvataggio",
                  variant: "danger",
                  solid: true
                });
              }
            })
            .catch(e => {
              this.$bvToast.toast(e.status, {
                title: "Errore nel salvataggio",
                variant: "danger",
                solid: true
              });
            })
            .finally(() => (this.loading = false));
        } else {
          return false;
        }
      });
    },
    handleDelete(index) {
      const route = `${process.env.VUE_APP_BACKEND_URL}/stores`;
      fetch(`${route}/${this.stores[index].id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`
        }
      })
        .then(() => {
          this.$bvToast.toast("Punto vendita eliminato con successo", {
            title: "Modifica effettuata",
            variant: "success",
            solid: true
          });
          this.stores.splice(index, 1);
        })
        .catch(e => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>
