import Vue from "vue";
import App from "./App.vue";
import VueCookies from "vue-cookies";
import router from "./router";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  BootstrapVue,
  BIcon,
  BIconX,
  BIconPencil,
  BIconFileEarmarkFill,
  BIconTrash,
  BIconFolder,
  BIconFolder2Open,
  BIconPlus
} from "bootstrap-vue";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/it";

import { required, max, alpha_num, numeric } from "vee-validate/dist/rules";

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY,
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true
    }),
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 0.4,
  environment: process.env.VUE_APP_SENTRY_ENV
});

extend("required", required);
extend("max", max);
extend("alpha_num", alpha_num);
extend("numeric", numeric);

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(ElementUI, { locale });
Vue.use(require("vue-moment"));
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("BIcon", BIcon);
Vue.component("BIconPencil", BIconPencil);
Vue.component("BIconFileEarmarkFill", BIconFileEarmarkFill);
Vue.component("BIconTrash", BIconTrash);
Vue.component("BIconFolder", BIconFolder);
Vue.component("BIconFolder2Open", BIconFolder2Open);
Vue.component("BIconX", BIconX);
Vue.component("BIconPlus", BIconPlus);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
