<template lang="pug">
  main
    HeaderWhite
    b-container#main-container.login
      b-overlay(:show="loading" spinner-small)
        b-alert(show variant="danger" v-if="!isUrlValid")
          | Il link di ripristino password non è valido
        b-card#center-form(v-else)
          img(src="/logo.png" alt="logo")
          b-form(@submit.stop.prevent="onSubmit()")
            div(v-if="uid && token")
              b-form-group.form-passw
                b-form-input(
                  placeholder="inserisci la tua nuova password" id="passw" required
                  v-model="form.new_password1" :type="(password_type)?'text':'password'"
                )
              b-form-group.form-passw
                b-form-input(
                  placeholder="ripeti la password" id="passw" required
                  v-model="form.new_password2" :type="(password_type)?'text':'password'"
                )
              b-form-checkbox(v-model="password_type") Visualizza password
            div(v-else)
              b-form-group#form-email
                b-form-input(
                  placeholder="inserisci la tua email" id="username" required
                  v-model="form.email" type="email"
                )
            b-form-group
              b-button(variant="secondary" size="lg" type="submit") Procedi
          a(href="/" style="text-align:center;display:block")
            | Torna al login
</template>

<script>
import HeaderWhite from "@/components/header-white.vue";

export default {
  name: "ForgotPassword",
  props: ["uid", "token"],
  components: {
    HeaderWhite,
  },
  data() {
    return {
      loading: false,
      form: {},
      password_type: false,
      isUrlValid: true,
    };
  },
  mounted() {
    if (!(this.$props.uid && this.$props.token)) {
      if (this.$route.fullPath.indexOf("/confirm/") > -1) {
        this.isUrlValid = false;
      }
    }
  },
  created() {
    const access_token = this.$cookies.get("access_token");
    if (access_token) window.location.href = "/exercise-sheets";
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let path = `${process.env.VUE_APP_BACKEND_URL}/auth/password/reset/`;
      let is_reset = false;
      if (this.$props.uid && this.$props.token) {
        is_reset = true;
        path += "confirm/";
        this.form.uid = this.$props.uid;
        this.form.token = this.$props.token;
      }
      fetch(path, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.form),
      })
        .then(async (response) => {
          const data = await response.json();
          const datakeys = Object.keys(data);
          if ((await response.status) == 200) {
            this.$bvToast.toast(data.detail, {
              title: is_reset ? "Password salvata" : "Controlla la tua posta",
              variant: "success",
              solid: true,
            });
          } else {
            this.$bvToast.toast(datakeys[0], {
              title: data[datakeys[0]],
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((e) => {
          this.$bvToast.toast(e.status, {
            title: "Errore",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
