<template lang="pug">
  main
    HeaderWhite
    b-container#main-container
      b-modal(
        id="modalform" @hidden="form={}" hide-footer
        @close="handleClose" @hide="handleClose"
      )
        el-form(:rules="rules" ref="form" :model="form")
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          FormInput(
            label="Nome azienda" :model="form.corporate_name"
            v-model="form.corporate_name" name="corporate_name"
          )
          FormInput(
            label="Via" :model="form.street"
            v-model="form.street" name="street"
          )
          FormInput(
            label="Città" :model="form.city" v-model="form.city" name="city"
          )
          FormInput(
            label="Provincia" :model="form.province"
            v-model="form.province" name="province"
          )
          FormInput(
            label="CAP" :model="form.postcode"
            v-model="form.postcode" name="postcode"
          )
          FormInput(
            label="P.IVA"
            :model="form.vat_registration_number"
            v-model="form.vat_registration_number"
            name="vat_registration_number"
          )
          FormInput(
            label="C.F." :model="form.tax_code"
            v-model="form.tax_code" name="tax_code"
          )
          FormInput(
            label="Banca" :model="form.bank" v-model="form.bank" name="bank"
          )
          FormInput(
            label="IBAN" :model="form.iban" v-model="form.iban" name="iban"
          )
          FormInput(
            label="Condizioni di pagamento"
            :model="form.payment_conditions"
            v-model="form.payment_conditions" name="payment_conditions"
          )
          FormInput(
            label="Numeri di telefono" :text="true"
            :model="form.telephone_numbers"
            v-model="form.telephone_numbers" name="telephone_numbers"
          )
          FormInput(
            label="Note" :text="true"
            :model="form.note"
            v-model="form.note" name="note"
          )
          b-button(type="button" variant="primary" @click="onSubmit") Salva
      div
        AdminSidebar(nav="customers")
        aside._body
          b-nav#header
            b-button(v-b-modal.modalform variant="primary") Nuovo cliente
            #right
              b-input(placeholder="Filtra" v-model="filter")
          b-table(
            responsive fixed hover :items="customers" :fields="fields"
            :filter="filter"
          )
            template(#cell(details)="row")
              b-button(size="sm" @click="row.toggleDetails" class="mr-2")
                | {{ row.detailsShowing ? 'Nascondi' : 'Mostra'}} dettagli
              b-button(
                size="sm" class="mr-2" variant="light" v-b-modal.modalform
                @click="handleEdit(row.item, row.index)"
              )
                b-icon-pencil
              b-button(
                size="sm" variant="danger" @click="handleDelete(row.index)"
              )
                b-icon-trash
            template(#row-details="row")
              b-card#details
                b-row
                  b-col(cols="6" lg="4")
                    b Creato: 
                    span {{ formate_date(row.item.created) }}
                  b-col(cols="6" lg="4")
                    b Modificato: 
                    span {{ formate_date(row.item.modified) }}
                  b-col(cols="6" lg="4")
                    b Nome azienda: 
                    span {{ row.item.corporate_name }}
                  b-col(cols="6" lg="4")
                    b Via: 
                    span {{ row.item.street }}
                  b-col(cols="6" lg="4")
                    b Città: 
                    span {{ row.item.city }}
                  b-col(cols="6" lg="4")
                    b Provincia: 
                    span {{ row.item.province }}
                  b-col(cols="6" lg="4")
                    b CAP: 
                    span {{ row.item.postcode }}
                  b-col(cols="6" lg="4")
                    b P.IVA: 
                    span {{ row.item.vat_registration_number }}
                  b-col(cols="6" lg="4")
                    b C.F: 
                    span {{ row.item.tax_code }}
                  b-col(cols="6" lg="4")
                    b Banca: 
                    span {{ row.item.bank }}
                  b-col(cols="6" lg="4")
                    b IBAN: 
                    span {{ row.item.iban }}
                  b-col(cols="6" lg="4")
                    b Condizioni di pagamento: 
                    span {{ row.item.payment_conditions }}
                  b-col(cols="6" lg="4")
                    b Numeri di telefono: 
                    span {{ row.item.telephone_numbers }}
                  b-col(cols="6" lg="4")
                    b Note: 
                    span {{ row.item.note }}
          #pagination
            b-pagination(
              v-model="currentPage" :total-rows="count" per-page="20"
              first-number last-number @change="changePag($event)"
            )
</template>

<script>
import HeaderWhite from "@/components/header-white.vue";
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";

export default {
  name: "Customers",
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput
  },
  data() {
    var checknumber = (rule, value, callback) => {
      if (!parseInt(value)) {
        callback(new Error("Non è un numero valido"));
      } else {
        callback();
      }
    };
    return {
      customers: [],
      form: {
        telephone_numbers: "",
        note: ""
      },
      temp_form: {
        index: null,
        data: {
          telephone_numbers: "",
          note: ""
        }
      },
      filter: "",
      fields: [
        { key: "id", label: "ID" },
        { key: "corporate_name", label: "Nome" },
        { key: "street", label: "Indirizzo" },
        { key: "telephone_numbers", label: "Telefono" },
        { key: "details", label: "" }
      ],
      rules: {
        corporate_name: [
          { required: true, message: "Il nome è obbligatorio" },
          { max: 30, message: "Il nome può essere lungo fino a 30 caratteri" }
        ],
        street: [
          { required: true, message: "L'indirizzo è obbligatorio" },
          {
            max: 30,
            message: "L'indirizzo può essere lungo fino a 30 caratteri"
          }
        ],
        city: [
          { required: true, message: "La città è obbligatoria" },
          { max: 30, message: "La città può essere lunga fino a 30 caratteri" }
        ],
        province: [
          { required: true, message: "La provincia è obbligatoria" },
          {
            max: 2,
            message: "La provincia può essere lunga fino a 2 caratteri"
          }
        ],
        postcode: [
          { required: true, message: "Il CAP è obbligatorio" },
          { max: 8, message: "Il CAP può essere lungo fino a 8 caratteri" }
        ],
        vat_registration_number: [
          { required: true, message: "La P.IVA è obbligatoria" },
          { max: 15, message: "La P.IVA può essere lunga fino a 15 caratteri" },
          { validator: checknumber }
        ],
        tax_code: [
          { required: true, message: "Il C.F. è obbligatorio" },
          { max: 15, message: "Il C.F. può essere lungo fino a 15 caratteri" }
        ],
        bank: [
          { required: true, message: "La banca è obbligatoria" },
          { max: 15, message: "La banca può essere lunga fino a 15 caratteri" }
        ],
        iban: [
          { required: true, message: "L'IBAN è obbligatorio" },
          { max: 30, message: "L'IBAN può essere lungo fino a 30 caratteri" }
        ],
        payment_conditions: [
          {
            required: true,
            message: "Le condizioni di pagamento sono obbligatorie"
          },
          {
            max: 30,
            message:
              "La condizioni di pagamento possono essere lunghe fino a 30 caratteri"
          }
        ]
      },
      count: 0,
      currentPage: 1,
      pageNum: 1
    };
  },
  mounted() {
    this.access_token = this.$cookies.get("access_token");
    this.pageNum = parseInt(this.$route.query["p"]) || 1;
    this.getData();
    setTimeout(() => {
      this.$set(this.$data, "currentPage", this.pageNum);
    }, 800);
  },
  methods: {
    getData() {
      let route = `${process.env.VUE_APP_BACKEND_URL}/customers/?limit=20&`;
      route += "offset=" + (this.pageNum - 1) * 20;
      fetch(route, {
        headers: {
          Authorization: `Bearer ${this.access_token}`
        }
      }).then(async response => {
        const data = await response.json();
        this.customers = data.results;
        this.count = data.count;
      });
    },
    changePag(num) {
      let url = this.$route.path;
      if (num > 1) url += "?p=" + num;

      window.location.href = url;
    },
    formate_date(text) {
      const _date = this.$moment(text, "YYYY-MM-DD hh:mm:ss");
      return _date.format("DD/MM/YYYY HH:mm");
    },
    handleEdit(data, index) {
      this.temp_form.index = index;
      this.temp_form.data = JSON.parse(JSON.stringify(data));
      this.form = data;
    },
    handleClose() {
      if (this.temp_form.index != null) {
        this.$set(this.customers, this.temp_form.index, this.temp_form.data);
        this.temp_form = {
          index: null,
          data: {
            telephone_numbers: "",
            note: ""
          }
        };
      }
    },
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let path = "";
          if (this.form.id) {
            path = `${process.env.VUE_APP_BACKEND_URL}/customers/${this.form.id}/`;
          } else {
            path = `${process.env.VUE_APP_BACKEND_URL}/customers/`;
          }
          const action = {
            method: this.form.id ? "PUT" : "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`
            }
          };

          this.loading = true;
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(this.form)
          })
            .then(async r => {
              const st = await r.status;
              if (st == 200 || st == 201) {
                this.$bvToast.toast("Cliente salvato con successo", {
                  title: "Modifica effettuata",
                  variant: "success",
                  solid: true
                });
                const data = await r.json();
                if (this.form.id) {
                  this.temp_form = {
                    index: null,
                    data: {}
                  };
                } else {
                  this.customers.push(data);
                }
                this.$bvModal.hide("modalform");
                this.form = {};
              } else {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Errore nel salvataggio",
                  variant: "danger",
                  solid: true
                });
              }
            })
            .catch(e => {
              this.$bvToast.toast(e.status, {
                title: "Errore nel salvataggio",
                variant: "danger",
                solid: true
              });
            })
            .finally(() => (this.loading = false));
        } else {
          return false;
        }
      });
    },
    handleDelete(index) {
      const route = `${process.env.VUE_APP_BACKEND_URL}/customers`;
      fetch(`${route}/${this.customers[index].id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`
        }
      })
        .then(() => {
          this.$bvToast.toast("Cliente eliminato con successo", {
            title: "Modifica effettuata",
            variant: "success",
            solid: true
          });
          this.customers.splice(index, 1);
        })
        .catch(e => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>
