<template lang="pug">
  b-nav#vertical-sidebar(vertical)
    b-nav-item(href="/exercise-sheets" :active="nav == 'exercisesheets'")
      | Fogli d'esercizio
    b-nav-item(href="/employees" :active="nav == 'employees'") Dipendenti
    b-nav-item(href="/vehicles" :active="nav == 'vehicles'") Veicoli
    b-nav-item(href="/customers" :active="nav == 'customers'") Clienti
    b-nav-item(href="/stores" :active="nav == 'stores'") Punti vendita
    b-nav-item(href="/operations" :active="nav == 'operations'")
      | Richieste d'intervento
    hr
    b-nav-item.logout(@click="logout('terminata')")
      b-button(variant="light") Logout
</template>

<script>
export default {
  name: "AdminSidebar",
  props: ["nav"],
  mounted() {
    window.setTimeout(() => {
      if (!this.$cookies.get("access_token")) this.logout("scaduta");
    }, 1000);
  },
  methods: {
    logout(sessionType) {
      this.$bvToast.toast("Effettua di nuovo il login", {
        title: "Sessione " + sessionType,
        variant: "warning",
        solid: true,
      });
      this.$cookies.remove("access_token");
      localStorage.removeItem("login");
      window.setTimeout(() => (window.location.href = "/"), 1000);
    },
  },
};
</script>
