<template lang="pug">
  main
    HeaderWhite
    b-container#main-container
      b-modal(
        id="modalform" @hidden="form={}" hide-footer
        @close="handleClose" @hide="handleClose"
      )
        el-form(:rules="rules" label-width="90px" ref="form" :model="form")
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          FormInput(
            label="Targa"
            :model="form.license_plate"
            v-model="form.license_plate" name="license_plate"
          )
          FormInput(
            label="Marchio"
            :model="form.brand" v-model="form.brand" name="brand"
          )
          FormInput(
            label="Modello"
            :model="form.model" v-model="form.model" name="model"
          )
          FormInput(
            label="Telaio"
            :model="form.frame" v-model="form.frame" name="frame"
          )
          b-button(type="button" variant="primary" @click="onSubmit") Salva
      div
        AdminSidebar(nav="vehicles")
        aside._body
          b-nav#header
            b-button(v-b-modal.modalform variant="primary") Nuovo veicolo
            #right
              b-input(placeholder="Filtra" v-model="filter")
          b-table(
            responsive fixed hover :items="vehicles" :fields="fields"
            :filter="filter"
          )
            template(#cell(details)="row")
              b-button(
                size="sm" class="mr-2" variant="light" v-b-modal.modalform
                @click="handleEdit(row.item, row.index)"
              )
                b-icon-pencil
              b-button(
                size="sm" variant="danger" @click="handleDelete(row.index)"
              )
                b-icon-trash
          #pagination
            b-pagination(
              v-model="currentPage" :total-rows="count" per-page="20"
              first-number last-number @change="changePag($event)"
            )
</template>

<script>
import HeaderWhite from "@/components/header-white.vue";
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";

export default {
  name: "Vehicles",
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput
  },
  data() {
    return {
      vehicles: [],
      form: {},
      temp_form: {
        index: null,
        data: {}
      },
      filter: "",
      fields: [
        { key: "id", label: "ID" },
        { key: "license_plate", label: "Targa" },
        { key: "brand", label: "Marchio" },
        { key: "model", label: "Modello" },
        { key: "frame", label: "Telaio" },
        { key: "details", label: "" }
      ],
      rules: {
        license_plate: [
          { required: true, message: "La targa è obbligatoria" },
          { max: 20, message: "La targa può essere lunga fino a 20 caratteri" }
        ],
        brand: [
          { required: true, message: "Il marchio è obbligatorio" },
          {
            max: 15,
            message: "Il marchio può essere lungo fino a 15 caratteri"
          }
        ],
        model: [
          { required: true, message: "Il modello è obbligatorio" },
          {
            max: 15,
            message: "Il modello può essere lungo fino a 15 caratteri"
          }
        ],
        frame: [
          { required: true, message: "Il telaio è obbligatorio" },
          {
            max: 15,
            message: "Il telaio può essere lungo fino a 15 caratteri"
          }
        ]
      },
      count: 0,
      currentPage: 1,
      pageNum: 1
    };
  },
  mounted() {
    this.access_token = this.$cookies.get("access_token");
    this.pageNum = parseInt(this.$route.query["p"]) || 1;
    this.getData();
    setTimeout(() => {
      this.$set(this.$data, "currentPage", this.pageNum);
    }, 800);
  },
  methods: {
    getData() {
      let route = `${process.env.VUE_APP_BACKEND_URL}/vehicles/?limit=20&`;
      route += "offset=" + (this.pageNum - 1) * 20;
      fetch(route, {
        headers: {
          Authorization: `Bearer ${this.access_token}`
        }
      }).then(async response => {
        const data = await response.json();
        this.vehicles = data.results;
        this.count = data.count;
      });
    },
    changePag(num) {
      let url = this.$route.path;
      if (num > 1) url += "?p=" + num;

      window.location.href = url;
    },
    handleEdit(data, index) {
      this.temp_form.index = index;
      this.temp_form.data = JSON.parse(JSON.stringify(data));
      this.form = data;
    },
    handleClose() {
      if (this.temp_form.index != null) {
        this.$set(this.vehicles, this.temp_form.index, this.temp_form.data);
        this.temp_form = {
          index: null,
          data: {}
        };
      }
    },
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let path = "";
          if (this.form.id) {
            path = `${process.env.VUE_APP_BACKEND_URL}/vehicles/${this.form.id}/`;
          } else {
            path = `${process.env.VUE_APP_BACKEND_URL}/vehicles/`;
          }
          const action = {
            method: this.form.id ? "PUT" : "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`
            }
          };

          this.loading = true;
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(this.form)
          })
            .then(async res => {
              const status = await res.status;
              if (status == 405 || status == 400) {
                this.$bvToast.toast("Veicolo già esistente", {
                  title: "Modifica non effettuata",
                  variant: "danger",
                  solid: true
                });
              } else {
                this.$bvToast.toast("Veicolo salvato con successo", {
                  title: "Modifica effettuata",
                  variant: "success",
                  solid: true
                });
                const data = await res.json();
                if (this.form.id) {
                  this.temp_form = {
                    index: null,
                    data: {}
                  };
                } else {
                  this.vehicles.push(data);
                }
                this.$bvModal.hide("modalform");
                this.form = {};
              }
            })
            .catch(e => {
              this.$bvToast.toast(e.status, {
                title: "Errore nel salvataggio",
                variant: "danger",
                solid: true
              });
            })
            .finally(() => (this.loading = false));
        } else {
          return false;
        }
      });
    },
    handleDelete(index) {
      const route = `${process.env.VUE_APP_BACKEND_URL}/vehicles`;
      fetch(`${route}/${this.vehicles[index].id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`
        }
      })
        .then(() => {
          this.$bvToast.toast("Veicolo eliminato con successo", {
            title: "Modifica effettuata",
            variant: "success",
            solid: true
          });
          this.vehicles.splice(index, 1);
        })
        .catch(e => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>
