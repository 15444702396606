<template lang="pug">
  header#white(:class="{active: loading}")
    #big-loading(v-if="loading")
      b-overlay(:show="true" spinner-large)
    .container
      nav.navbar
        a(href="/" rel="home")
          img(src="/logo.png" alt="logo")
          h3 E.M.C. Manutenzione S.r.l.
    footer#cookies(v-if="show_cookies_banner")
      span Utilizzando questo sito accetti automaticamente l'utilizzo dei 
        | cookies, anche di terze parti, i quali vengono usati per migliorare 
        | l'utilizzo di E.M.C. Manutenzione S.r.l.. Se vuoi sapere cosa essi siano, 
        | vai al sito ufficiale cliccando 
        a(href="https://www.garanteprivacy.it/cookie" target="_new") qui.
      b-button(variant="link" @click="accept_cookies()")
        b-icon-x
</template>

<script>
export default {
  name: "HeaderWhite",
  data() {
    return {
      show_cookies_banner: true,
      loading: true,
    };
  },
  async mounted() {
    const cookies = this.$cookies.get("accept_cookies");
    if (cookies) this.show_cookies_banner = false;

    const access_token = this.$cookies.get("access_token");
    if (access_token) {
      const res = await fetch(
        `${process.env.VUE_APP_BACKEND_URL}/auth/token/verify/`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: access_token }),
        }
      );

      if ((await res.status) != 200) {
        const login = localStorage.getItem("login");
        if (!login) {
          this.$cookies.remove("access_token");
        } else {
          await fetch(`${process.env.VUE_APP_BACKEND_URL}/auth/login/`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: login,
          })
            .then(async (response) => {
              const data = await response.json();
              const datakeys = Object.keys(data);
              if (datakeys.indexOf("access_token") > -1) {
                this.$cookies.set("access_token", data.access_token);
              } else {
                this.$cookies.remove("access_token");
                localStorage.removeItem("login");
              }
            })
            .catch(() => {
              this.$cookies.remove("access_token");
              localStorage.removeItem("login");
            });
        }
      }
    }

    this.loading = false;
  },
  methods: {
    accept_cookies() {
      this.$cookies.set("accept_cookies", true);
      this.show_cookies_banner = false;
    },
  },
};
</script>
