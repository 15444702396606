<template lang="pug">
  main
    HeaderWhite
    b-container#main-container
      b-modal(
        id="modalform" @hidden="form={}" hide-footer
        @close="handleClose" @hide="handleClose"
      )
        el-form(:rules="rules" label-width="90px" ref="form" :model="form")
          span.asterisk I campi seguiti da <code>*</code> sono obbligatori
          FormInput(
            label="Nome"
            :model="form.name" v-model="form.name" name="name"
          )
          FormInput(
            label="Indirizzo"
            :model="form.street" v-model="form.street" name="street"
          )
          FormInput(
            label="Città"
            :model="form.city" v-model="form.city" name="city"
          )
          FormInput(
            label="Provincia"
            :model="form.province" v-model="form.province" name="province"
          )
          FormInput(
            label="Telefono"
            :model="form.telephone_number" v-model="form.telephone_number"
            name="telephone_number"
          )
          b-button(type="button" variant="primary" @click="onSubmit") Salva
      div
        AdminSidebar(nav="employees")
        aside._body
          b-nav#header
            b-button(v-b-modal.modalform variant="primary") Nuovo dipendente
            #right
              b-input(placeholder="Filtra" v-model="filter" class="search")
          b-table(
            responsive fixed hover :items="employees" :fields="fields"
            :filter="filter"
          )
            template(#cell(details)="row")
              b-button(
                size="sm" class="mr-2" variant="light" v-b-modal.modalform
                @click="handleEdit(row.item, row.index)"
              )
                b-icon-pencil
              b-button(
                size="sm" variant="danger" @click="handleDelete(row.index)"
              )
                b-icon-trash

          #pagination
            b-pagination(
              v-model="currentPage" :total-rows="count" per-page="20"
              first-number last-number @change="changePag($event)"
            )
</template>

<script>
import HeaderWhite from "@/components/header-white.vue";
import AdminSidebar from "@/components/admin-sidebar.vue";
import FormInput from "@/components/form-input.vue";

export default {
  name: "Employees",
  components: {
    HeaderWhite,
    AdminSidebar,
    FormInput
  },
  data() {
    var checknumber = (rule, value, callback) => {
      if (!parseInt(value)) {
        callback(new Error("Non è un numero valido"));
      } else {
        callback();
      }
    };
    return {
      employees: [],
      form: {},
      temp_form: {
        index: null,
        data: {}
      },
      filter: "",
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Nome" },
        { key: "street", label: "Indirizzo" },
        { key: "telephone_number", label: "Telefono" },
        { key: "details", label: "" }
      ],
      rules: {
        name: [
          { required: true, message: "Il nome è obbligatorio" },
          { max: 30, message: "Il nome può essere lungo fino a 30 caratteri" }
        ],
        street: [
          { required: true, message: "L'indirizzo è obbligatorio" },
          {
            max: 30,
            message: "L'indirizzo può essere lungo fino a 30 caratteri"
          }
        ],
        city: [
          { required: true, message: "La città è obbligatoria" },
          { max: 30, message: "La città può essere lunga fino a 30 caratteri" }
        ],
        province: [
          { required: true, message: "La provincia è obbligatoria" },
          {
            max: 2,
            message: "La provincia può essere lunga fino a 2 caratteri"
          }
        ],
        telephone_number: [
          { required: true, message: "Il telefono è obbligatorio" },
          {
            max: 12,
            message: "Il telefono può essere lungo fino a 12 caratteri"
          },
          { validator: checknumber }
        ]
      },
      count: 0,
      currentPage: 1,
      pageNum: 1
    };
  },
  mounted() {
    this.access_token = this.$cookies.get("access_token");
    this.pageNum = parseInt(this.$route.query["p"]) || 1;
    this.getData();
    setTimeout(() => {
      this.$set(this.$data, "currentPage", this.pageNum);
    }, 800);
  },
  methods: {
    getData() {
      let route = `${process.env.VUE_APP_BACKEND_URL}/employees/?limit=20&`;
      route += "offset=" + (this.pageNum - 1) * 20;
      fetch(route, {
        headers: {
          Authorization: `Bearer ${this.access_token}`
        }
      }).then(async response => {
        const data = await response.json();
        this.employees = data.results;
        this.count = data.count;
      });
    },
    changePag(num) {
      let url = this.$route.path;
      if (num > 1) url += "?p=" + num;

      window.location.href = url;
    },
    handleEdit(data, index) {
      this.temp_form.index = index;
      this.temp_form.data = JSON.parse(JSON.stringify(data));
      this.form = data;
    },
    handleClose() {
      if (this.temp_form.index != null) {
        this.$set(this.employees, this.temp_form.index, this.temp_form.data);
        this.temp_form = {
          index: null,
          data: {}
        };
      }
    },
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let path = "";
          if (this.form.id) {
            path = `${process.env.VUE_APP_BACKEND_URL}/employees/${this.form.id}/`;
          } else {
            path = `${process.env.VUE_APP_BACKEND_URL}/employees/`;
          }
          const action = {
            method: this.form.id ? "PUT" : "POST",
            url: path,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.access_token}`
            }
          };

          this.loading = true;
          fetch(action.url, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(this.form)
          })
            .then(async r => {
              const st = await r.status;
              if (st == 200 || st == 201) {
                this.$bvToast.toast("Dipendente salvato con successo", {
                  title: "Modifica effettuata",
                  variant: "success",
                  solid: true
                });
                const data = await r.json();
                if (this.form.id) {
                  this.temp_form = {
                    index: null,
                    data: {}
                  };
                } else {
                  this.employees.push(data);
                }
                this.$bvModal.hide("modalform");
                this.form = {};
              } else {
                this.$bvToast.toast(`Errore ${st}`, {
                  title: "Errore nel salvataggio",
                  variant: "danger",
                  solid: true
                });
              }
            })
            .catch(e => {
              this.$bvToast.toast(e.status, {
                title: "Errore nel salvataggio",
                variant: "danger",
                solid: true
              });
            })
            .finally(() => (this.loading = false));
        } else {
          return false;
        }
      });
    },
    handleDelete(index) {
      const route = `${process.env.VUE_APP_BACKEND_URL}/employees`;
      fetch(`${route}/${this.employees[index].id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`
        }
      })
        .then(() => {
          this.$bvToast.toast("Dipendente eliminato con successo", {
            title: "Modifica effettuata",
            variant: "success",
            solid: true
          });
          this.employees.splice(index, 1);
        })
        .catch(e => {
          this.$bvToast.toast(e.status, {
            title: "Errore nel salvataggio",
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>
